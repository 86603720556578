import ReactGA from "react-ga4"
import _ from "lodash"

import appEventEmitter, { AppEvents } from './AppEventEmitter'
import { getUserIdFromLocalStorage } from '../helpers/helper'

const GoogleAnalyticsTracker = {
  isInitialized: false,

  _registerEvent: function (eventName, callback) {
    return appEventEmitter.addListener(eventName, callback)
  },

  _unRegisterEvents: function () {
    // todo
  },

  init: function () {
    if (this.isInitialized)
      return

    if (!GA4_MEASUREMENT_ID) {
      console.warn('Google Analytics not initialized, since measurement id not supplied')
      return
    }

    console.debug('Google Analytics initialized with measurement id:', GA4_MEASUREMENT_ID)
    ReactGA.initialize(GA4_MEASUREMENT_ID, {
      testMode: false,
      gaOptions: {
        trackingId: GA_TRACKING_ID,
        currencyCode: 'USD',
        debug_mode: false,
      },
      gtagOptions: {
        currency: 'USD',
      }
    })

    this._registerEvent(AppEvents.signup_success, (e) => {
      let user_id = _.get(e, 'user_id', null)
      let email = _.get(e, 'email', null)
      let method = _.get(e, 'signup_method', 'email')
      let source = _.get(e, 'signup_source', 'direct')
      ReactGA.event('sign_up', { method, source, user_id, email, co_user_id: user_id })
      ReactGA.event('sign_up', { method, source, user_id, email, co_user_id: user_id, send_to: GA_TRACKING_ID })
    })

    this._registerEvent(AppEvents.user_login_success, (e) => {
      let user_id = _.get(e, 'user_id', null)
      let email = _.get(e, 'email', null)
      let method = _.get(e, 'signin_method', 'email')
      let source = _.get(e, 'signin_source', 'direct')

      ReactGA.set({ user_id })
      ReactGA.event('login', { method, source, email, co_user_id: user_id })
      ReactGA.event('login', { method, source, email, co_user_id: user_id, send_to: GA_TRACKING_ID })
    })

    this._registerEvent(AppEvents.user_clicked_logout_btn, () => {
      let user_id = null
      ReactGA.set({ user_id })
      ReactGA.event('logout', { co_user_id: user_id })
      ReactGA.event('logout', { co_user_id: user_id, send_to: GA_TRACKING_ID })
    })

    this._registerEvent(AppEvents.user_payment_success, (e) => {
      let user_id = getUserIdFromLocalStorage()

      let eventData = {
        transaction_id: e.eventData.order_id, // order id
        co_user_id: user_id,
        value: e.eventData.amount, // total price paid by the user inclusive of tax
        tax: e.eventData.tax, // tax if any
        currency: e.eventData.currency, // currency
        coupon: e.eventData.coupon, // coupon id if any
        items: [
          {
            item_id: e.eventData.product_id, // product id
            item_name: e.eventData.name, // product display name
            coupon: e.eventData.coupon, // coupon id
            discount: e.eventData.discount, // discount value if any
            quantity: e.eventData.quantity, // quantity purchased by the user
            item_variant: e.eventData.recurring ? 'subscription' : 'one-time', // subscription / one-time
            item_category: e.eventData.type // addon / plan
          }
        ]
      }

      ReactGA.set({ user_id })
      ReactGA.event('purchase', eventData)
    })

    this.isInitialized = true
  },

  send: function ({ hitType, page_location, page_title }) {
    ReactGA.send({ hitType, page_location, page_title })
  }
}

export default GoogleAnalyticsTracker
